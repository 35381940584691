<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">FAQs</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'faqs' }">FAQs</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add New
              </li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <form
            @submit.prevent="addNew"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            Background
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Background 1200 * 440
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabMask" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Mask')"
                            aria-expanded="false"
                            aria-controls="collapseMask"
                            data-toggle="collapse"
                            data-target="#collapseMask"
                          >
                            Mask
                            <span
                              id="iconToggleMask"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseMask"
                          class="collapse"
                          aria-labelledby="TabMask"
                          data-parent="#accordion"
                        >
                          <div class="col-12 row pt-3">
                            <!-- Title -->
                            <div class="form-group col-9">
                              <label for="input1">background Title</label>
                              <vue-editor
                                id="input1"
                                v-model="row.bgTitle"
                                :init="{
                                  height: 200,
                                  menubar: editor.menubar,
                                  plugins: editor.plugins,
                                  toolbar: editor.toolbar,
                                }"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group col-3">
                              <label for="input2">background Color</label>
                              <input
                                class="form-control"
                                id="input2"
                                type="color"
                                style="height: 200px"
                                v-model="row.bgColor"
                              />
                            </div>
                            <!-- End Slug -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Content
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Title -->
                            <div class="form-group">
                              <label for="input1">Title</label>
                              <input
                                class="form-control"
                                id="input1"
                                type="text"
                                v-model="row.title"
                                @keyup="onTitleChange"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group">
                              <label for="input2">Slug</label>
                              <input
                                class="form-control text-lowercase"
                                id="input2"
                                type="text"
                                v-model="row.slug"
                                @keydown.space.prevent
                                @paste="onSlugPaste"
                                @change="onSlugChange(false)"
                              />
                            </div>
                            <!-- End Slug -->

                            <!-- Sort -->
                            <div class="form-group">
                              <label for="input3">Sort</label>
                              <input
                                class="form-control"
                                id="input3"
                                min="0"
                                type="number"
                                v-model.number="row.sort"
                              />
                            </div>
                            <!-- End Sort -->

                            <!-- Body -->
                            <div class="form-group">
                              <label for="input4">Body</label>
                              <vue-editor
                                id="input4"
                                v-model="row.body"
                                :init="{
                                  height: 600,
                                  menubar: editor.menubar,
                                  plugins: editor.plugins,
                                  toolbar: editor.toolbar,
                                }"
                              />
                            </div>
                            <!-- End Body -->

                            <h4><br />Status & Visibility</h4>
                            <div class="col-md-12">
                              <!-- Status -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch1"
                                    :checked="row.status"
                                    @click="onStatus"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch1"
                                    v-html="row.status ? 'Active' : 'Inactive'"
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Status -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Create FAQs</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";

import iziToast from "izitoast";

export default {
  name: "Create",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        status: 1,

        preview: "",
        image: "",

        sort: 0,
        title: "",
        slug: "",
        body: "",
        bgTitle: "",
        bgColor: "",
      },
      editor: {
        api_key: "xahz1dg338xnac8il0tkxph26xcaxqaewi3bd9cw9t4e6j7b",
        menubar: "file edit view insert format tools table tc help",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help",
      },
      croppieImage: "",
      cropped: false,
      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
  },
  methods: {
    // Add New
    addNew() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/faqs",
        method: "POST",
        data: {
          status: this.row.status,
          image: this.row.image,
          sort: this.row.sort,
          slug: this.row.slug,
          title: this.row.title,
          body: this.row.body,
          bgTitle: this.row.bgTitle,
          bgColor: this.row.bgColor,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Added Successfully.",
          });
          this.$router.push({ name: "faqs" });
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Title
    onTitleChange() {
      this.onSlugChange(this.row.title);
    },

    // on Paste
    onSlugPaste() {
      let str = this.row.slug;
      this.onSlugChange(str);
    },
    onSlugChange(str) {
      if (!str) {
        let str = this.row.slug;
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      } else {
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      }
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    // active status
    onStatus() {
      if (this.row.status) this.row.status = 0;
      else this.row.status = 1;
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "faqs" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
