<template>
  <div class="">
    <Header v-on:headerToChild="onSearchSubmit"></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2" style="text-transform: capitalize">
            {{ type }} &nbsp;<router-link
              v-if="
                type == 'accreditation-applications' ||
                type == 'certificate-applications'
              "
              :to="{
                name: 'update-online-applications2',
                params: { type: type },
              }"
              class="btn btn-primary btn-sm btn-pill ui-mt-10 ui-mb-2"
            >
              <span> Update {{ type }}</span>
            </router-link>
            <router-link
              v-else
              :to="{
                name: 'update-online-applications',
                params: { type: type },
              }"
              class="btn btn-primary btn-sm btn-pill ui-mt-10 ui-mb-2"
            >
              <span> Update {{ type }}</span>
            </router-link>
          </h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ type }}
              </li>
            </ol>

            <!-- Build Action button -->
            <div class="pull-rights ui-mt-50 pull-right">
              <div class="dropdown display-flex-inline">
                <div class="dropdown">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :disabled="exportLoading"
                  >
                    <span v-if="!exportLoading">Export</span>
                    <span v-if="exportLoading">
                      <span
                        class="spinner-grow spinner-grow-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      ></span
                      >Loading...
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <download-excel
                      class="dropdown-item cursor-pointer"
                      :fetch="fetchExport"
                      :fields="exp.json_fields"
                      :before-generate="startDownload"
                      :before-finish="finishDownload"
                      :worksheet="type"
                      :name="type + '.xls'"
                      >Excel
                    </download-excel>
                    <download-excel
                      class="dropdown-item cursor-pointer"
                      :fetch="fetchExport"
                      :fields="exp.json_fields"
                      :before-generate="startDownload"
                      :before-finish="finishDownload"
                      type="csv"
                      :worksheet="type"
                      :name="type + '.xls'"
                      >CSV
                    </download-excel>
                    <a
                      class="dropdown-item"
                      href="javascript:;"
                      v-print="'#printMe'"
                      >Print</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Card -->
          <div class="row">
            <div class="col-md-12 mb-5">
              <div class="card">
                <header class="card-header">
                  <h2 class="h4 card-header-title">
                    <router-link
                      class="pg-hd"
                      style="font-size: 16px"
                      :to="{
                        name: 'online-applications',
                        params: { type: 'trainings-applications' },
                      }"
                      :class="type == 'trainings-applications' ? 'active' : ''"
                      >Trainings</router-link
                    >
                    <span class="pg-hd no-decoration f14">
                      ({{ statusBar.app1 }})
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp; &nbsp;&nbsp;&nbsp;

                    <router-link
                      class="pg-hd"
                      style="font-size: 16px"
                      :to="{
                        name: 'online-applications',
                        params: { type: 'memberships-applications' },
                      }"
                      :class="
                        type == 'memberships-applications' ? 'active' : ''
                      "
                      >Memberships</router-link
                    >
                    <span class="pg-hd no-decoration f14">
                      ({{ statusBar.app2 }})
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;

                    <router-link
                      class="pg-hd"
                      style="font-size: 16px"
                      :to="{
                        name: 'online-applications',
                        params: { type: 'instructor-applications' },
                      }"
                      :class="type == 'instructor-applications' ? 'active' : ''"
                      >Iam instructor</router-link
                    >
                    <span class="pg-hd no-decoration f14">
                      ({{ statusBar.app3 }})
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;

                    <router-link
                      class="pg-hd"
                      style="font-size: 16px"
                      :to="{
                        name: 'online-applications',
                        params: { type: 'experience-applications' },
                      }"
                      :class="type == 'experience-applications' ? 'active' : ''"
                      >I've Experience</router-link
                    >
                    <span class="pg-hd no-decoration f14">
                      ({{ statusBar.app4 }})
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;

                    <router-link
                      class="pg-hd"
                      style="font-size: 16px"
                      :to="{
                        name: 'online-applications',
                        params: { type: 'accreditation-applications' },
                      }"
                      :class="
                        type == 'accreditation-applications' ? 'active' : ''
                      "
                      >Accreditations</router-link
                    >
                    <span class="pg-hd no-decoration f14">
                      ({{ statusBar.app5 }})
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;

                    <router-link
                      class="pg-hd"
                      style="font-size: 16px"
                      :to="{
                        name: 'online-applications',
                        params: { type: 'certificate-applications' },
                      }"
                      :class="
                        type == 'certificate-applications' ? 'active' : ''
                      "
                      >Certifications</router-link
                    >
                    <span class="pg-hd no-decoration f14">
                      ({{ statusBar.app6 }})
                    </span>

                    <!-- Show Entries -->
                    <div class="dropdown pull-right ui-mt-10">
                      <button
                        type="button"
                        class="btn btn-light btn-sm dropdown-toggle"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span>Show</span>
                        <span v-if="!showLoading"> {{ show }}</span>
                        <span v-if="showLoading">
                          <span
                            class="spinner-grow spinner-grow-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </span>
                      </button>
                      <div
                        class="dropdown-menu ui-min-w100"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item cursor-pointer"
                          :class="show == 10 ? 'active' : ''"
                          @click="onShow(10)"
                          >10
                        </a>
                        <a
                          class="dropdown-item cursor-pointer"
                          :class="show == 25 ? 'active' : ''"
                          @click="onShow(25)"
                          >25
                        </a>
                        <a
                          class="dropdown-item cursor-pointer"
                          :class="show == 50 ? 'active' : ''"
                          @click="onShow(50)"
                          >50
                        </a>
                        <a
                          class="dropdown-item cursor-pointer"
                          :class="show == 100 ? 'active' : ''"
                          @click="onShow(100)"
                          >100
                        </a>
                      </div>
                    </div>
                    <!-- End Show Entries -->
                  </h2>
                </header>
                <!-- End Card Header -->

                <!-- Crad Body -->
                <div class="card-body pt-0">
                  <div class="table-responsive">
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th style="width: 5%">
                            <div class="custom-control custom-checkbox">
                              <input
                                id="expBox0"
                                class="custom-control-input"
                                type="checkbox"
                                v-model="selectAll"
                                @click="select"
                              />
                              <label
                                class="custom-control-label"
                                for="expBox0"
                              ></label>
                            </div>
                          </th>
                          <th style="width: 15%">
                            Name
                            <span
                              v-if="!orderLoading"
                              @click="onOrderBy('first_name')"
                              class="cursor-pointer"
                              :class="
                                order == 'DESC'
                                  ? 'ti-arrow-down'
                                  : order == 'ASC'
                                  ? 'ti-arrow-up'
                                  : 'ti-exchange-vertical'
                              "
                            >
                            </span>
                            <span v-if="orderLoading">
                              <span
                                class="spinner-grow spinner-grow-sm mr-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </span>
                          </th>
                          <th style="width: 10%" class="text-center">
                            E-mail Address
                          </th>
                          <th class="text-center" style="width: 10%">
                            Telephone No
                          </th>
                          <th class="text-center" style="width: 10%">
                            Nationality
                            <span
                              v-if="!natLoading && filter_by == 'nationality'"
                              @click="remove1Filter()"
                              class="cursor-pointer ti-close ui-ml5"
                            >
                            </span>
                            <span v-if="natLoading" class="ui-ml5">
                              <div class="loader loader-sm"></div>
                            </span>
                          </th>
                          <th class="text-center" style="width: 10%">
                            Program
                            <span
                              v-if="!proLoading && filter_by == 'program'"
                              @click="remove2Filter()"
                              class="cursor-pointer ti-close ui-ml5"
                            >
                            </span>
                            <span v-if="proLoading" class="ui-ml5">
                              <div class="loader loader-sm"></div>
                            </span>
                          </th>
                          <th class="text-center" style="width: 15%">Date</th>
                          <th class="text-center" style="width: 10%">
                            Actions
                          </th>
                        </tr>
                      </thead>

                      <tbody v-if="dataLoading">
                        <tr>
                          <td colspan="7" class="text-center">
                            <div class="spinner-grow" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-if="!dataLoading && !rows.length">
                        <tr>
                          <td colspan="7" class="text-center">
                            <span>No results found.</span>
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-if="!dataLoading && rows.length">
                        <tr
                          v-for="(row, index) in rows"
                          :key="index"
                          class="f14"
                        >
                          <td class="font-weight-semi-bold">
                            <div class="custom-control custom-checkbox">
                              <input
                                :id="'expBox' + row.id"
                                class="custom-control-input"
                                type="checkbox"
                                v-model="selected"
                                :value="row.id"
                              />
                              <label
                                class="custom-control-label"
                                :for="'expBox' + row.id"
                              >
                              </label>
                            </div>
                          </td>

                          <td class="font-weight-semi-bold">
                            <router-link
                              v-if="
                                type == 'accreditation-applications' ||
                                type == 'certificate-applications'
                              "
                              :to="{
                                name: 'show-online-applications2',
                                params: { type: type, id: row.encrypt_id },
                              }"
                              class="default-color text-decoration-hover"
                            >
                              {{ row.name }}
                            </router-link>
                            <router-link
                              v-else
                              :to="{
                                name: 'show-online-applications',
                                params: { type: type, id: row.encrypt_id },
                              }"
                              class="default-color text-decoration-hover"
                            >
                              {{ row.name }}
                            </router-link>
                          </td>

                          <td class="font-weight-semi-bold text-center">
                            {{ row.email_Address }}
                          </td>

                          <td class="font-weight-semi-bold text-center">
                            {{ row.telephone_no }}
                          </td>

                          <td class="font-weight-semi-bold text-center">
                            <router-link
                              :to="{
                                name: 'online-applications-filter',
                                params: {
                                  type: type,
                                  id: row.encrypt_id,
                                  filter_by: 'nationality',
                                  filter: row.nationality_slug,
                                },
                              }"
                              class="default-color text-decoration-hover"
                            >
                              <span
                                class="badge badge-md badge-pill badge-danger-soft"
                              >
                                {{ row.nationality }}
                              </span>
                            </router-link>
                          </td>

                          <td class="font-weight-semi-bold text-center">
                            <span v-if="!row.program"> - </span>
                            <router-link
                              v-if="row.program"
                              :to="{
                                name: 'online-applications-filter',
                                params: {
                                  type: type,
                                  id: row.encrypt_id,
                                  filter_by: 'program',
                                  filter: row.program_slug,
                                },
                              }"
                              class="default-color text-decoration-hover"
                            >
                              <span
                                class="badge badge-md badge-pill badge-info-soft"
                              >
                                {{ row.program }}
                              </span>
                            </router-link>
                          </td>

                          <td
                            v-html="
                              row.deleted_at
                                ? row.deleted_at
                                : row.updated_at
                                ? row.updated_at
                                : row.created_at
                            "
                            class="font-weight-semi-bold text-center f12"
                          ></td>

                          <td class="text-center">
                            <div class="dropdown">
                              <a
                                id="tableWithImage1MenuInvoker"
                                class="u-icon-sm link-muted"
                                href="javascript:;"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                                data-toggle="dropdown"
                                data-offset="8"
                              >
                                <span
                                  v-if="!row.loading"
                                  class="ti-more"
                                ></span>
                                <span
                                  v-if="row.loading"
                                  class="spinner-grow spinner-grow-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                >
                                </span>
                              </a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style="width: 150px"
                              >
                                <div class="card border-0 p-3">
                                  <ul class="list-unstyled mb-0">
                                    <li v-if="!row.trash">
                                      <router-link
                                        v-if="
                                          type ==
                                            'accreditation-applications' ||
                                          type == 'certificate-applications'
                                        "
                                        class="d-block link-dark"
                                        :to="{
                                          name: 'show-online-applications2',
                                          params: {
                                            type: type,
                                            id: row.encrypt_id,
                                          },
                                        }"
                                      >
                                        Show
                                      </router-link>
                                      <router-link
                                        v-else
                                        class="d-block link-dark"
                                        :to="{
                                          name: 'show-online-applications',
                                          params: {
                                            type: type,
                                            id: row.encrypt_id,
                                          },
                                        }"
                                      >
                                        Show
                                      </router-link>
                                    </li>
                                    <li>
                                      <a
                                        @click="
                                          row.loading = true;
                                          deletePermanently(row.id);
                                        "
                                        class="d-block link-dark"
                                        href="javascript:;"
                                        >Delete
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>
                            <div class="custom-control custom-checkbox">
                              <input
                                id="expBox0"
                                class="custom-control-input"
                                type="checkbox"
                                v-model="selectAll"
                                @click="select"
                              />
                              <label
                                class="custom-control-label"
                                for="expBox0"
                              ></label>
                            </div>
                          </th>
                          <th>Name</th>
                          <th class="text-center">E-mail Address</th>
                          <th class="text-center">Telephone No</th>
                          <th class="text-center">Nationality</th>
                          <th class="text-center">Program</th>
                          <th class="text-center">Date</th>
                          <th class="text-center">Actions</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <nav
                    v-if="rows.length !== 0"
                    aria-label="Page navigation example ui-mt20"
                    style="margin-top: 20px"
                  >
                    <ul class="pagination">
                      <li
                        v-bind:class="[{ disabled: !pagination.prev_page_url }]"
                        class="page-item"
                      >
                        <a
                          class="page-link"
                          href="javascript:"
                          @click="fetchData(pagination.prev_page_url, true)"
                          >Previous</a
                        >
                      </li>
                      <li
                        v-bind:class="[{ disabled: !pagination.next_page_url }]"
                        class="page-item"
                      >
                        <a
                          class="page-link"
                          href="javascript:"
                          @click="fetchData(pagination.next_page_url, true)"
                          >Next</a
                        >
                      </li>
                    </ul>
                    <p class="pull-right ui-mt-50 f13">
                      <i
                        >Page {{ pagination.current_page }} or
                        {{ pagination.last_page }}</i
                      >
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Content Body -->

        <Footer></Footer>
      </div>
      <!-- Content -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";

export default {
  name: "List",
  components: {
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
      exp: {
        json_fields: {},
        json_data: [],
        json_meta: [
          [
            {
              key: "charset",
              value: "utf-8",
            },
          ],
        ],
      },
      auth: {
        access_token: "",
      },
      statusBar: {
        app1: 0,
        app2: 0,
        app3: 0,
        app4: 0,
        app5: 0,
        app6: 0,
      },
      //
      search: "",
      status: "",
      order: "",
      order_by: "",

      selected: [],
      selectAll: false,
      plural: "",

      type: "Trainings Application",

      dataLoading: true,
      bulkLoading: false,
      exportLoading: false,
      showLoading: false,
      orderLoading: false,
      btnLoading: false,
      something_went_wrong: false,
      rows: [],
      show: 10,
      pagination: {},
      btn_status: "Create",
      filter_by: "",
      filter: "",
      natLoading: false,
      proLoading: false,
    };
  },
  mounted() {},
  watch: {
    $route() {
      // Status By
      if (this.$route.params.status) {
        this.status = this.$route.params.status;
      } else {
        this.status = "";
      }

      // Filters by
      if (this.$route.params.filter_by) {
        this.filter_by = this.$route.params.filter_by;
      }
      if (this.$route.params.filter) {
        this.filter = this.$route.params.filter;
      }

      if (this.$route.params.type) {
        this.type = this.$route.params.type;
      }

      this.fetchData("", true);
    },
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    // Status By
    if (this.$route.params.status) {
      this.status = this.$route.params.status;
    }

    // Filters by
    if (this.$route.params.filter_by) {
      this.filter_by = this.$route.params.filter_by;
    }
    if (this.$route.params.filter) {
      this.filter = this.$route.params.filter;
    }

    if (this.$route.params.type) {
      this.type = this.$route.params.type;
    }

    this.fetchData("", true);
  },
  methods: {
    onSearchSubmit(value) {
      this.search = value;
      this.fetchData("", true);
    },

    onShow(show) {
      this.showLoading = true;
      this.show = show;
      this.fetchData("", true);
    },

    onOrderBy(column) {
      this.orderLoading = true;
      this.order_by = column;
      if (this.order == "ASC") {
        this.order = "DESC";
      } else {
        this.order = "ASC";
      }
      this.fetchData("", true);
    },

    // Fetch Data
    fetchData(page_url, loading = false) {
      if (loading) {
        this.dataLoading = true;
      }
      (this.plural = ""), (this.selectAll = false);
      this.selected = [];

      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      let vm = this;
      const options = {
        url: page_url || window.baseURL + "/applications/" + this.type,
        method: "GET",
        data: {},
        params: {
          status: this.status,
          search: this.search,
          show: this.show,
          order: this.order,
          order_by: this.order_by,
          filter_by: this.filter_by,
          filter: this.filter,
        },
      };
      this.axios(options)
        .then((res) => {
          this.dataLoading = false;
          this.bulkLoading = false;
          this.showLoading = false;
          this.orderLoading = false;
          this.natLoading = false;
          this.proLoading = false;

          this.statusBar.app1 = res.data.statusBar.app1;
          this.statusBar.app2 = res.data.statusBar.app2;
          this.statusBar.app3 = res.data.statusBar.app3;
          this.statusBar.app4 = res.data.statusBar.app4;
          this.statusBar.app5 = res.data.statusBar.app5;
          this.statusBar.app6 = res.data.statusBar.app6;

          this.rows = res.data.rows;
          if (res.data.paginate.total) {
            vm.makePagination(res.data.paginate);
          }
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Pagination
    makePagination(meta) {
      let pagination = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        next_page_url: meta.next_page_url,
        prev_page_url: meta.prev_page_url,
      };
      this.pagination = pagination;
    },

    remove1Filter() {
      this.natLoading = true;
      this.filter = "";
      this.filter_by = "";
      this.$router.push({
        name: "online-applications",
        params: { type: this.type },
      });
    },
    remove2Filter() {
      this.proLoading = true;
      this.filter = "";
      this.filter_by = "";
      this.$router.push({
        name: "online-applications",
        params: { type: this.type },
      });
    },

    // Fetch Export to Excel, CSV
    async fetchExport() {
      if (
        this.type == "trainings-applications" ||
        this.type == "memberships-applications" ||
        this.type == "instructor-applications" ||
        this.type == "experience-applications"
      ) {
        this.exp.json_fields = {
          id: "id",
          first_name: "first_name",
          middle_name: "middle_name",
          last_name: "last_name",
          email_Address: "email_Address",
          telephone_no: "telephone_no",
          nationality: "nationality",
          program: "pname",
          video_url: "video_url",
          created_at: "created_at",
        };
      } else {
        this.exp.json_fields = {
          id: "id",
          name_of_institution: "name_of_institution",
          address: "address",
          name: "name",
          email_Address: "email_Address",
          telephone_no: "telephone_no",
          country: "country",
          state: "state",
          program: "pname",
          date: "date",
          created_at: "created_at",
        };
      }
      const res = await this.axios.get(
        window.baseURL +
          "/applications/" +
          this.type +
          "/export/file?id=" +
          this.selected
      );
      return res.data.rows;
    },
    startDownload() {
      this.exportLoading = true;
    },
    finishDownload() {
      this.exportLoading = false;
      iziToast.success({
        icon: "ti-check",
        title: "Great job,",
        message: "File Generated Successfully",
      });
    },

    // remove sessions
    removeLocalStorage() {
      localStorage.removeItem("access_token");
    },

    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.rows) {
          this.selected.push(this.rows[i].id);
        }
      }
    },

    // Delete Permanently
    deletePermanently(id) {
      if (confirm("Are You Sure?")) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/applications/" + this.type + "/" + id,
          method: "DELETE",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData();
            iziToast.success({
              icon: "ti-check",
              title: "Great job,",
              message: "Item Deleted Permanently Successfully",
            });
          })
          .catch(() => {
            this.fetchData();
            iziToast.error({
              icon: "ti-na",
              title: "Wow-wow,",
              message: "Something went wrong",
            });
          })
          .finally(() => {});
      } else {
        // In case Cancel Alert
        this.fetchData();
      }
    },
  },
};
</script>
