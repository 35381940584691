<template>
    <div class="">
        <Header></Header>

        <!-- Main -->
        <main class="u-main">
            <Navigation></Navigation>

            <div class="u-content">
                <div class="u-body min-h-700">
                    <h1 class="h2 mb-2" style="text-transform: capitalize">{{ type }}</h1>

                    <!-- Breadcrumb -->
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'online-applications', params:{type: type} }">{{ type }}</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Show</li>
                        </ol>
                    </nav>
                    <!-- End Breadcrumb -->

             <!-- Loading -->
            <div v-if="pgLoading" class="row h-100">
                <div class="container text-center">
                    <div class="spinner-grow mt-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <!-- End Loading -->

            <form v-if="!pgLoading" enctype="multipart/form-data" class="h-100">

            <!-- Content -->
            <div class="tab-content">
                <div class="row">        


                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">

                    <!-- Card Personal -->
                    <div class="card">
                        <div class="card-body">
                            <div id="accordion" class="accordion">
                                <div id="TabPersonal" class="card-header">
                                    <h2 class="h4 card-header-title" 
                                        @click="collapseToggle('Personal')"
                                        aria-expanded="false" 
                                        aria-controls="collapsePersonal" 
                                        data-toggle="collapse"
                                        data-target="#collapsePersonal">Step 1
                                        <span id="iconTogglePersonal" 
                                            class="ti-angle-up u-sidebar-nav-menu__item-arrow 
                                                pull-right black">
                                        </span>
                                    </h2>
                                </div>
                                <div id="collapsePersonal" 
                                    class="collapse show" 
                                    aria-labelledby="TabPersonal" 
                                    data-parent="#accordion">

                            <div class="row col-12">
                                <h3><br/>Corporate Information<br/></h3>
                                    <p><br/></p>

                                    <div v-if="row.logo" class="form-group col-12">
                                        <label>Logo</label>
                                        <p><img :src="row.logo" style="width: 100px"></p>
                                        <p>
                                            <a :href="row.logo" 
                                            target="_blacnk">Preview Full screen
                                            </a>
                                        </p>
                                    </div>

                                    <div class="form-group col-12">
                                        <label for="input1">Name of Instittution / Training Center</label>
                                        <input class="form-control" 
                                                id="input1" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.name_of_institution">
                                    </div>
                                    <div class="form-group col-12">
                                        <label for="input2">Address</label>
                                        <input class="form-control" 
                                                id="input2" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.address">
                                    </div>

                                <div class="col-md-6 col-sm-12" style="padding:0">
                                    <div class="form-group col-12">
                                        <label for="input3">Country</label>
                                        <input class="form-control" 
                                                id="input3" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.country">
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12" style="padding:0">
                                    <div class="form-group col-12">
                                        <label for="input4">State/Provice/Region:</label>
                                        <input class="form-control" 
                                                id="input4" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.state">
                                    </div>
                                </div>

                                    <div class="form-group col-12">
                                        <label for="input5">Type-of Organization (Legislation)</label>
                                        <input class="form-control" 
                                                id="input5" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.type">
                                    </div>

                                <div class="col-md-6 col-sm-12" style="padding:0">
                                    <div class="form-group col-12">
                                        <label for="input6">Establishment date</label>
                                        <input class="form-control" 
                                                id="input6" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.date">
                                    </div>
                                    <div class="form-group col-12">
                                        <label for="input8">Telephone No</label>
                                        <input class="form-control" 
                                                id="input8" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.telephone_no">
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12" style="padding:0">
                                    <div class="form-group col-12">
                                        <label for="input7">Commerical Register No.:</label>
                                        <input class="form-control" 
                                                id="input7" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.commerical_register_no">
                                    </div>
                                    <div class="form-group col-12">
                                        <label for="input9">E-mail Address</label>
                                        <input class="form-control" 
                                                id="input9" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.email_Address">
                                    </div>
                                </div>  

                                    <div class="form-group col-12">
                                        <label for="input10">Website Address (URL)</label>
                                        <input class="form-control" 
                                                id="input10" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.website_url">
                                    </div>


                                <p style="width: 100%"><br/></p>
                                <h3>Documents</h3>
                                <p style="width: 100%"><br/></p>
                                
                                    <div class="col-md-6 col-sm-12" style="padding:0">
                                        <div class="form-group col-12" v-if="row.file1">
                                            <p><label>Copy of documents of incorporation</label></p>
                                            <p>&nbsp;&nbsp;<a v-if="row.file1"
                                                :href="row.file1" 
                                                target="_blank">Preview
                                            </a></p>
                                        </div>
                                        <div class="form-group col-12" v-if="row.file3">
                                            <p><label>Informational materials</label></p>
                                            <p>&nbsp;&nbsp;<a v-if="row.file3"
                                                :href="row.file3" 
                                                target="_blank">Preview
                                            </a></p>
                                        </div>
                                        <div class="form-group col-12" v-if="row.file5">
                                            <p><label>Facilities</label></p>
                                            <p>&nbsp;&nbsp;<a v-if="row.file5"
                                                :href="row.file5" 
                                                target="_blank">Preview
                                            </a></p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12" style="padding:0">
                                        <div class="form-group col-12" v-if="row.file2">
                                            <p><label>Upload company profile</label></p>
                                            <p>&nbsp;&nbsp;<a v-if="row.file2"
                                                :href="row.file2" 
                                                target="_blank">Preview
                                            </a></p>
                                        </div>
                                        <div class="form-group col-12" v-if="row.file4">
                                            <p><label>Curriculum</label></p>
                                            <p>&nbsp;&nbsp;<a v-if="row.file4"
                                                :href="row.file4" 
                                                target="_blank">Preview
                                            </a></p>
                                        </div>
                                        <div class="form-group col-12" v-if="row.file6">
                                            <p><label>Student services</label></p>
                                            <p>&nbsp;&nbsp;<a v-if="row.file6"
                                                :href="row.file6" 
                                                target="_blank">Preview
                                            </a></p>
                                        </div>
                                    </div>


                                </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Card Personal -->

                    <!-- Card Personal 2 -->
                    <div class="card mt-5">
                        <div class="card-body">
                            <div id="accordion2" class="accordion">
                                <div id="TabPersonal2" class="card-header">
                                    <h2 class="h4 card-header-title" 
                                        @click="collapseToggle('Personal2')"
                                        aria-expanded="false" 
                                        aria-controls="collapsePersonal2" 
                                        data-toggle="collapse"
                                        data-target="#collapsePersonal2">Step 2
                                        <span id="iconTogglePersonal2" 
                                            class="ti-angle-up u-sidebar-nav-menu__item-arrow 
                                                pull-right black">
                                        </span>
                                    </h2>
                                </div>
                                <div id="collapsePersonal2" 
                                    class="collapse" 
                                    aria-labelledby="TabPersonal2" 
                                    data-parent="#accordion2">

                            <div class="row col-12">
                                <p><br/></p>
                                <h3>General Characteristics</h3>
                                <p><br/></p>

                                    <div class="col-12 row">
                                        <p>1.</p>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general1Yes" 
                                                        :checked="row.general1 == 1">
                                                <label class="custom-control-label" 
                                                        for="general1Yes" style="color:#333">Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general1No" 
                                                        :checked="row.general1 == 0">
                                                <label class="custom-control-label" 
                                                        for="general1No" style="color:#333">No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 row">
                                        <p>2.</p>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general2Yes" 
                                                        :checked="row.general2 == 1">
                                                <label class="custom-control-label" 
                                                        for="general2Yes" style="color:#333">Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general2No" 
                                                        :checked="row.general2 == 0">
                                                <label class="custom-control-label" 
                                                        for="general2No" style="color:#333">No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 row">
                                        <p>3.</p>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general3Yes" 
                                                        :checked="row.general3 == 1">
                                                <label class="custom-control-label" 
                                                        for="general3Yes" style="color:#333">Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general3No" 
                                                        :checked="row.general3 == 0">
                                                <label class="custom-control-label" 
                                                        for="general3No" style="color:#333">No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 row">
                                        <p>4.</p>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general4Yes" 
                                                        :checked="row.general4 == 1">
                                                <label class="custom-control-label" 
                                                        for="general4Yes" style="color:#333">Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general4No" 
                                                        :checked="row.general4 == 0">
                                                <label class="custom-control-label" 
                                                        for="general4No" style="color:#333">No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 row">
                                        <p>5.</p>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general5Yes" 
                                                        :checked="row.general5 == 1">
                                                <label class="custom-control-label" 
                                                        for="general5Yes" style="color:#333">Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-1">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="general5No" 
                                                        :checked="row.general5 == 0">
                                                <label class="custom-control-label" 
                                                        for="general5No" style="color:#333">No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Card Personal 2 -->

                    
                    <!-- Card Personal 3 -->
                    <div class="card mt-5">
                        <div class="card-body">
                            <div id="accordion3" class="accordion">
                                <div id="TabPersonal3" class="card-header">
                                    <h2 class="h4 card-header-title" 
                                        @click="collapseToggle('Personal3')"
                                        aria-expanded="false" 
                                        aria-controls="collapsePersonal3" 
                                        data-toggle="collapse"
                                        data-target="#collapsePersonal3">Step 3
                                        <span id="iconTogglePersonal3" 
                                            class="ti-angle-up u-sidebar-nav-menu__item-arrow 
                                                pull-right black">
                                        </span>
                                    </h2>
                                </div>
                                <div id="collapsePersonal3" 
                                    class="collapse" 
                                    aria-labelledby="TabPersonal3" 
                                    data-parent="#accordion2">

                            <div class="row col-12">
                                <p><br/></p>
                                <h3>Authority</h3>
                                <p><br/></p>

                                    <div class="form-group col-12 row">
                                        <p>1.</p>
                                        <div class="form-group col-4">
                                            <div class="custom-control custom-switch mb-2">
                                                    <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="authority1" 
                                                        :checked="row.authority1 == 1">
                                                    <label class="custom-control-label" 
                                                        for="authority1">
                                                    </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 row">
                                        <p>2.</p>
                                        <div class="form-group col-4">
                                            <div class="custom-control custom-switch mb-2">
                                                    <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="authority2" 
                                                        :checked="row.authority2 == 1">
                                                    <label class="custom-control-label" 
                                                        for="authority2">
                                                    </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 row">
                                        <p>3.</p>
                                        <div class="form-group col-4">
                                            <div class="custom-control custom-switch mb-2">
                                                    <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="authority3" 
                                                        :checked="row.authority3 == 1">
                                                    <label class="custom-control-label" 
                                                        for="authority3">
                                                    </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 row">
                                        <p>4.</p>
                                        <div class="form-group col-4">
                                            <div class="custom-control custom-switch mb-2">
                                                    <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="authority4" 
                                                        :checked="row.authority4 == 1">
                                                    <label class="custom-control-label" 
                                                        for="authority4">
                                                    </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 row">
                                        <p>5.</p>
                                        <div class="form-group col-4">
                                            <div class="custom-control custom-switch mb-2">
                                                    <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="authority5" 
                                                        :checked="row.authority5 == 1">
                                                    <label class="custom-control-label" 
                                                        for="authority5">
                                                    </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 row">
                                        <p>6.</p>
                                        <div class="form-group col-4">
                                            <div class="custom-control custom-switch mb-2">
                                                    <input type="checkbox" 
                                                        class="custom-control-input" 
                                                        disabled="" 
                                                        id="authority6" 
                                                        :checked="row.authority6 == 1">
                                                    <label class="custom-control-label" 
                                                        for="authority6">
                                                    </label>
                                            </div>
                                        </div>
                                    </div>
                                    

                                <p><br/></p>
                                <h3>Organization's Chairman / Manager or Head Director</h3>
                                <p><br/></p>

                                <div class="col-12"><br/></div>
                                <div class="col-md-6 col-sm-12" style="padding:0">
                                    <div class="form-group col-12">
                                        <label for="input3">Name</label>
                                        <input class="form-control" 
                                                id="input3" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.name">
                                    </div>
                                    <div class="form-group col-12">
                                        <label for="input3">Date</label>
                                        <input class="form-control" 
                                                id="input3" 
                                                type="text" 
                                                disabled="" 
                                                :value="row.date">
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12" style="padding: 0">
                                    <div class="form-group">
                                        <label for="input3">Signature</label>
                                        <p>&nbsp;&nbsp;<a v-if="row.file7"
                                            :href="row.file7" 
                                            target="_blank">Preview
                                        </a></p>
                                    </div>
                                </div>

                                </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Card Personal 3 -->            

                </div>
                <!-- ******* End Card ******** -->


                
                </div>
            </div>
            <!-- End Content -->

                </form>
            </div>

                <Footer></Footer>
            </div>
        </main>
        <!-- End Main -->
        
    </div>
</template>

<script>
    import Header from '../layouts/Header.vue';
    import Navigation from '../layouts/Navigation';
    import Footer from '../layouts/Footer.vue';
    import iziToast from 'izitoast';
    
    export default {
        name: 'Show',
        components: {
            Header,
            Navigation,
            Footer
        },
        data(){
            return {
                auth: {
                    access_token: '',
                },
                pgLoading: true,
                type: '',
                row: '',
            }
        },
        mounted() {},
        computed: {},
        created() {
            if(localStorage.getItem('access_token')) {
                this.auth.access_token = localStorage.getItem('access_token');
            }
            if(this.$route.params.type) {
                this.type = this.$route.params.type;
            }

            this.fetchRow();
        },
        methods: {
            
            fetchRow() {
                this.pgLoading = true;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const options = {
                    url: window.baseURL+'/new-app/'+this.$route.params.id,
                    method: 'GET',
                    data: {},
                    params: {},
                }
                this.axios(options)
                    .then(res => {
                    this.pgLoading = false;
                    this.row = res.data.row;
                    })
                    .catch(err => {
                        this.pgLoading = false;
                        iziToast.warning({
                            icon: 'ti-alert',
                            title: 'Wow-man,',
                            message: (err.response) ? err.response.data.message : ''+err
                        });
                    })
                    .finally(() => {});
            },

            // toggleCollapse
            collapseToggle(div) {
                let el = document.querySelector("span#iconToggle"+div);
                if(el.classList.contains('ti-angle-down')) {
                    el.classList.remove('ti-angle-down');
                    el.classList.add('ti-angle-up');
                } else {
                    el.classList.remove('ti-angle-up');
                    el.classList.add('ti-angle-down');
                }
            },

            // Cancel
            cancel(){
                if(confirm('Are You Sure?')) {
                    this.$router.push({ name: 'online-applications', params:{type: this.type} });
                }
            },


        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
