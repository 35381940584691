<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Sliders</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'sliders' }">Sliders </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add New
              </li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <form
            @submit.prevent="addNew"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            Background
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Image 1600 * 590
                                <button
                                  v-if="row.preview_image"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview_image = '';
                                    row.image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview_image"
                                  :src="row.preview_image"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>

                            <div class="form-group">
                              <label
                                >Or Video 1600 * 590
                                <button
                                  v-if="row.preview_video"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview_video = '';
                                    row.video = '';
                                  "
                                >
                                  Remove Video
                                </button>
                              </label>
                              <p>
                                <iframe
                                  v-if="row.preview_video"
                                  :src="row.preview_video"
                                  style="width: 100%; height: 600px"
                                >
                                </iframe>
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onVideoChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->

                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Content
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row">
                              <div class="form-group col-12">
                                <label>Title</label>
                                <vue-editor
                                  id="input04"
                                  v-model="row.title"
                                  :init="{
                                    height: 300,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <!-- <div class="col-1">
                                                <label>Font</label>
                                                <input type="color" v-model="row.title_color" style="height: 175px;width:100px">
                                            </div> -->
                            </div>

                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-6">
                                <label>Background Color</label>
                                <input
                                  type="color"
                                  class="form-control"
                                  v-model="row.bgColor"
                                  style="height: 60px; width: 100%"
                                />
                              </div>
                              <div class="form-group col-6">
                                <label>Line Color</label>
                                <input
                                  type="color"
                                  class="form-control"
                                  v-model="row.lineColor"
                                  style="height: 60px; width: 100%"
                                />
                              </div>
                              <div class="form-group col-6">
                                <label>Button</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.button"
                                />
                              </div>
                              <div class="form-group col-6">
                                <label>Redirect</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.redirect"
                                />
                              </div>

                              <!-- Sort -->
                              <div class="form-group col-6">
                                <label for="input3">Sort</label>
                                <input
                                  class="form-control"
                                  id="input3"
                                  min="0"
                                  type="number"
                                  v-model.number="row.sort"
                                />
                              </div>
                              <div class="form-group col-6">
                                <label for="input3"
                                  >Duration (per second)</label
                                >
                                <input
                                  class="form-control"
                                  id="input3"
                                  min="1"
                                  type="number"
                                  v-model.number="row.duration"
                                />
                              </div>
                            </div>
                            <!-- End Sort -->

                            <h4><br />Status & Visibility</h4>
                            <div class="col-md-12">
                              <!-- Status -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch1"
                                    :checked="row.status"
                                    @click="onStatus"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch1"
                                    v-html="row.status ? 'Active' : 'Inactive'"
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Status -->

                              <!-- <div class="form-group">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                    class="custom-control-input" 
                                                    id="customSwitch4" 
                                                    :checked="row.has_download"
                                                    @click="onDownload">
                                                <label class="custom-control-label" 
                                                    for="customSwitch4"
                                                    v-html="(row.has_download) ? 'Show Download File' : 'Hide Download File'">
                                                </label>
                                            </div>
                                        </div> -->

                              <!-- <div class="col-12 row" v-if="row.has_download">
                                                <div class="form-group col-8">
                                                    <label>Download Title</label>
                                                    <input type="text" class="form-control" 
                                                        v-model="row.download_name">
                                                </div>
                                                <div class="form-group col-4">
                                                    <label>Upload File
                                                        <button v-if="row.download_preview" 
                                                            type="button" 
                                                            class="btn btn-danger btn-sm btn-pill"
                                                            style='margin-top: -10px; margin-left: 10px'
                                                            @click="row.download_preview = ''; row.download_file = ''">
                                                            Remove File
                                                        </button>
                                                    </label>
                                                    <a v-if="row.download_preview" 
                                                        :href="row.download_preview" 
                                                        target="_blank" class="pull-right">Preview
                                                    </a>
                                                    <input type="file" class="form-control" 
                                                    @change="onFileChange">
                                                </div>
                                                <div class="form-group col-md-6 col-sm-12">
                                                <label>Download Preview
                                                    <button v-if="row.download_image_preview" 
                                                        type="button" 
                                                        class="btn btn-danger btn-sm btn-pill"
                                                        style='margin-top: -10px; margin-left: 10px'
                                                        @click="row.download_image_preview = ''; 
                                                                row.download_image = ''">
                                                        Remove Image
                                                    </button>
                                                </label>
                                                <p>
                                                    <img v-if="row.download_image_preview" 
                                                        :src="row.download_image_preview"
                                                        style="max-width: 50%">
                                                </p>
                                                <input type="file" class="form-control" @change="onFilePreviewChange">
                                            </div>
                                        </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Create Slider</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";

import iziToast from "izitoast";

export default {
  name: "Create",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        status: 1,

        preview_image: "",
        image: "",

        lineColor: "",
        bgColor: "",
        button: "",
        redirect: "",

        preview_video: "",
        video: "",

        sort: 0,
        duration: 5,

        title: "",
        title_color: "#000000",
        subtitle: "",
        subtitle_color: "#000000",
      },
      editor: {
        api_key: "xahz1dg338xnac8il0tkxph26xcaxqaewi3bd9cw9t4e6j7b",
        menubar: "file edit view insert format tools table tc help",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help",
      },
      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
  },
  methods: {
    // Add New
    addNew() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/sliders",
        method: "POST",
        data: {
          status: this.row.status,
          sort: this.row.sort,
          duration: this.row.duration,

          bgColor: this.row.bgColor,
          lineColor: this.row.lineColor,
          button: this.row.button,
          redirect: this.row.redirect,

          image: this.row.image,
          video: this.row.video,

          title: this.row.title,
          title_color: this.row.title_color,
          subtitle: this.row.subtitle,
          subtitle_color: this.row.subtitle_color,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Added Successfully.",
          });
          this.$router.push({ name: "sliders" });
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Upload Featured image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview_image = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },
    onVideoChange(e) {
      const file = e.target.files[0];
      this.row.preview_video = URL.createObjectURL(file);
      this.createBase64Video(file);
    },
    createBase64Video(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.video = e.target.result;
      };
    },

    // active status
    onStatus() {
      if (this.row.status) this.row.status = 0;
      else this.row.status = 1;
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "sliders" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
