<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Home</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Home</li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="spinner-grow mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!-- End Loading -->

          <!-- Form -->
          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <div class="tab-content">
              <div class="row">
                <div class="col-md-12 mb-5" style="display: none">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Image
                                <button
                                  v-if="row.preview1"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview1 = '';
                                    row.image1 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview1"
                                  :src="row.preview1"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImage1Change"
                              />
                            </div>
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-12">
                                <label>Title</label>
                                <vue-editor
                                  id="editors0title1"
                                  v-model="row.title1"
                                  :init="{
                                    height: 300,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>

                              <div class="col-6">
                                <label>Background Color</label>
                                <input
                                  type="color"
                                  v-model="row.bgColor1"
                                  style="height: 50px; width: 100%"
                                />
                              </div>

                              <div class="col-6">
                                <label>Line Color</label>
                                <input
                                  type="color"
                                  v-model="row.lineColor1"
                                  style="height: 50px; width: 100%"
                                />
                              </div>
                            </div>

                            <div
                              class="col-12 row"
                              style="margin-top: 20px; padding: 0"
                            >
                              <div class="form-group col-12">
                                <label>Button</label>
                                <input
                                  type="text"
                                  v-model="row.button1"
                                  class="form-control"
                                />
                              </div>
                              <div class="form-group col-12">
                                <label>Redirect</label>
                                <input
                                  type="text"
                                  v-model="row.redirect1"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background2')"
                            aria-expanded="false"
                            aria-controls="collapseBackground2"
                            data-toggle="collapse"
                            data-target="#collapseBackground2"
                          >
                            <span
                              id="iconToggleBackground2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground2"
                          class="collapse show"
                          aria-labelledby="TabBackground2"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Image 215 * 235
                                <button
                                  v-if="row.preview2"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview2 = '';
                                    row.image2 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview2"
                                  :src="row.preview2"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImage2Change"
                              />
                            </div>
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-12">
                                <label>Title</label>

                                <vue-editor
                                  id="editors0title2"
                                  v-model="row.title2"
                                  :init="{
                                    height: 300,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>

                              <div class="col-12">
                                <label>Background Color</label>
                                <input
                                  type="color"
                                  v-model="row.bgColor2"
                                  style="height: 50px; width: 100%"
                                />
                              </div>
                            </div>
                            <div
                              class="col-12 row"
                              style="margin-top: 10px; padding: 0"
                            >
                              <div class="form-group col-12">
                                <label>Button</label>
                                <input
                                  type="text"
                                  v-model="row.button2"
                                  class="form-control"
                                />
                              </div>
                              <div class="form-group col-12">
                                <label>Redirect</label>
                                <input
                                  type="text"
                                  v-model="row.redirect2"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background3')"
                            aria-expanded="false"
                            aria-controls="collapseBackground3"
                            data-toggle="collapse"
                            data-target="#collapseBackground3"
                          >
                            <span
                              id="iconToggleBackground3"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground3"
                          class="collapse show"
                          aria-labelledby="TabBackground3"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Image 215 * 235
                                <button
                                  v-if="row.preview3"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview3 = '';
                                    row.image3 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview3"
                                  :src="row.preview3"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImage3Change"
                              />
                            </div>
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-12">
                                <label>Title</label>

                                <vue-editor
                                  id="editors0title3"
                                  v-model="row.title3"
                                  :init="{
                                    height: 300,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>

                              <div class="col-12">
                                <label>Background Color</label>
                                <input
                                  type="color"
                                  v-model="row.bgColor3"
                                  style="height: 50px; width: 100%"
                                />
                              </div>
                            </div>
                            <div
                              class="col-12 row"
                              style="margin-top: 10px; padding: 0"
                            >
                              <div class="form-group col-12">
                                <label>Button</label>
                                <input
                                  type="text"
                                  v-model="row.button3"
                                  class="form-control"
                                />
                              </div>
                              <div class="form-group col-12">
                                <label>Redirect</label>
                                <input
                                  type="text"
                                  v-model="row.redirect3"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background4')"
                            aria-expanded="false"
                            aria-controls="collapseBackground4"
                            data-toggle="collapse"
                            data-target="#collapseBackground4"
                          >
                            <span
                              id="iconToggleBackground4"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground4"
                          class="collapse show"
                          aria-labelledby="TabBackground4"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-12">
                                <label>Title</label>

                                <vue-editor
                                  id="editors0title4"
                                  v-model="row.title4"
                                  :init="{
                                    height: 300,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>

                              <div class="col-12">
                                <label>Background Color</label>
                                <input
                                  type="color"
                                  v-model="row.bgColor4"
                                  style="height: 50px; width: 100%"
                                />
                              </div>
                              <div
                                class="form-group col-12"
                                style="margin-top: 10px"
                              >
                                <label>Content</label>

                                <vue-editor
                                  id="editors0content4"
                                  v-model="row.content4"
                                  :init="{
                                    height: 300,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                            </div>
                            <div
                              class="col-12 row"
                              style="margin-top: 10px; padding: 0"
                            >
                              <div class="form-group col-12">
                                <label>Button</label>
                                <input
                                  type="text"
                                  v-model="row.button4"
                                  class="form-control"
                                />
                              </div>
                              <div class="form-group col-12">
                                <label>Redirect</label>
                                <input
                                  type="text"
                                  v-model="row.redirect4"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background5')"
                            aria-expanded="false"
                            aria-controls="collapseBackground5"
                            data-toggle="collapse"
                            data-target="#collapseBackground5"
                          >
                            <span
                              id="iconToggleBackground5"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground5"
                          class="collapse show"
                          aria-labelledby="TabBackground5"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-6">
                                <label
                                  >Image 440 * 435
                                  <button
                                    v-if="row.preview5"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview5 = '';
                                      row.image5 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview5"
                                    :src="row.preview5"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage5Change"
                                />
                              </div>
                              <div class="form-group col-6 row">
                                <div class="col-6">
                                  <label>Background Color</label>
                                  <input
                                    type="color"
                                    v-model="row.bgColor5"
                                    style="height: 50px; width: 100%"
                                  />
                                </div>
                                <div class="col-6">
                                  <label>Line Color</label>
                                  <input
                                    type="color"
                                    v-model="row.lineColor5"
                                    style="height: 50px; width: 100%"
                                  />
                                </div>
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>SubTitle</label>
                                  <!--        <input type="text" 
                                                            class="form-control" 
                                                            v-model="row.subTitle5"> -->
                                  <vue-editor
                                    id="editors0subTitle5"
                                    v-model="row.subTitle5"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>
                                <!-- <div class="col-2" style="margin-top: 10px">
                                                        <label>Font</label>
                                                        <input type="color" 
                                                                v-model="row.subTitlecolor5" 
                                                                style="height: 50px;width: 100%">
                                                    </div> -->
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>

                                  <vue-editor
                                    id="editors0title5"
                                    v-model="row.title5"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Content</label>
                                  <!-- <textarea 
                                                            class="form-control" 
                                                            v-model="row.content5"
                                                            rows="5" 
                                                            style="font-size: 16px">
                                                        </textarea> -->
                                  <vue-editor
                                    id="editors0content5"
                                    v-model="row.content5"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>
                                <!-- <div class="col-2">
                                                        <label>Font</label>
                                                        <input type="color" 
                                                                v-model="row.contentColor5" 
                                                                style="height: 130px;width: 100%">
                                                    </div> -->
                                <div
                                  class="col-12 row"
                                  style="margin-top: 10px"
                                >
                                  <div class="form-group col-12">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button5"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-12">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect5"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground6" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background6')"
                            aria-expanded="false"
                            aria-controls="collapseBackground6"
                            data-toggle="collapse"
                            data-target="#collapseBackground6"
                          >
                            <span
                              id="iconToggleBackground6"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground6"
                          class="collapse show"
                          aria-labelledby="TabBackground6"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-6 row">
                                <div class="col-6">
                                  <label>Background Color</label>
                                  <input
                                    type="color"
                                    v-model="row.bgColor6"
                                    style="height: 50px; width: 100%"
                                  />
                                </div>
                                <div class="col-6">
                                  <label>Line Color</label>
                                  <input
                                    type="color"
                                    v-model="row.lineColor6"
                                    style="height: 50px; width: 100%"
                                  />
                                </div>
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>SubTitle</label>

                                  <vue-editor
                                    id="editors0subTitle6"
                                    v-model="row.subTitle6"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>
                                <!-- <div class="col-2" style="margin-top: 10px">
                                                        <label>Font</label>
                                                        <input type="color" 
                                                                v-model="row.subTitleColor6" 
                                                                style="height: 50px;width: 100%">
                                                    </div> -->
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>

                                  <vue-editor
                                    id="editors0title6"
                                    v-model="row.title6"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Content</label>

                                  <vue-editor
                                    id="editors0content6"
                                    v-model="row.content6"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="col-12 row"
                                  style="margin-top: 10px"
                                >
                                  <div class="form-group col-12">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button6"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-12">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect6"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="form-group col-6">
                                <label
                                  >Image 440 * 435
                                  <button
                                    v-if="row.preview6"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview6 = '';
                                      row.image6 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview6"
                                    :src="row.preview6"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage6Change"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground7" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background7')"
                            aria-expanded="false"
                            aria-controls="collapseBackground7"
                            data-toggle="collapse"
                            data-target="#collapseBackground7"
                          >
                            <span
                              id="iconToggleBackground7"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground7"
                          class="collapse show"
                          aria-labelledby="TabBackground7"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row">
                              <div class="form-group">
                                <label
                                  >Image 90 * 90
                                  <button
                                    v-if="row.preview7"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview7 = '';
                                      row.image7 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview7"
                                    :src="row.preview7"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage7Change"
                                />
                              </div>
                              <div
                                class="form-group col-12 row"
                                style="padding: 0"
                              >
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>

                                  <vue-editor
                                    id="editors0title7"
                                    v-model="row.title7"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Content</label>

                                  <vue-editor
                                    id="editors0content7"
                                    v-model="row.content7"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>
                                <!--  <div class="col-2" style="padding: 0">
                                                        <label>Font</label>
                                                        <input type="color" 
                                                                v-model="row.contentColor7" 
                                                                style="height: 130px;width: 100%">
                                                    </div> -->
                                <div
                                  class="col-12 row"
                                  style="padding: 0; margin-left: 0"
                                >
                                  <div class="form-group col-12">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button7"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-12">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect7"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground8" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background8')"
                            aria-expanded="false"
                            aria-controls="collapseBackground8"
                            data-toggle="collapse"
                            data-target="#collapseBackground8"
                          >
                            <span
                              id="iconToggleBackground8"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground8"
                          class="collapse show"
                          aria-labelledby="TabBackground8"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row">
                              <div class="form-group">
                                <label
                                  >Image 90 * 90
                                  <button
                                    v-if="row.preview8"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview8 = '';
                                      row.image8 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview8"
                                    :src="row.preview8"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage8Change"
                                />
                              </div>
                              <div
                                class="form-group col-12 row"
                                style="padding: 0"
                              >
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>

                                  <vue-editor
                                    id="editors0title8"
                                    v-model="row.title8"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Content</label>

                                  <vue-editor
                                    id="editors0content8"
                                    v-model="row.content8"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="col-12 row"
                                  style="padding: 0; margin-left: 0"
                                >
                                  <div class="form-group col-12">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button8"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-12">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect8"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground9" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background9')"
                            aria-expanded="false"
                            aria-controls="collapseBackground9"
                            data-toggle="collapse"
                            data-target="#collapseBackground9"
                          >
                            <span
                              id="iconToggleBackground9"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground9"
                          class="collapse show"
                          aria-labelledby="TabBackground9"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row">
                              <div class="form-group">
                                <label
                                  >Image 90 * 90
                                  <button
                                    v-if="row.preview9"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview9 = '';
                                      row.image9 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview9"
                                    :src="row.preview9"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage9Change"
                                />
                              </div>
                              <div
                                class="form-group col-12 row"
                                style="padding: 0"
                              >
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>

                                  <vue-editor
                                    id="editors0title9"
                                    v-model="row.title9"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Content</label>

                                  <vue-editor
                                    id="editors0content9"
                                    v-model="row.content9"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="col-12 row"
                                  style="padding: 0; margin-left: 0"
                                >
                                  <div class="form-group col-12">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button9"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-12">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect9"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground10" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background10')"
                            aria-expanded="false"
                            aria-controls="collapseBackground10"
                            data-toggle="collapse"
                            data-target="#collapseBackground10"
                          >
                            <span
                              id="iconToggleBackground10"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground10"
                          class="collapse show"
                          aria-labelledby="TabBackground10"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row">
                              <div class="form-group">
                                <label
                                  >Image 90 * 90
                                  <button
                                    v-if="row.preview10"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview10 = '';
                                      row.image10 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview10"
                                    :src="row.preview10"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage10Change"
                                />
                              </div>
                              <div
                                class="form-group col-12 row"
                                style="padding: 0"
                              >
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>

                                  <vue-editor
                                    id="editors0title10"
                                    v-model="row.title10"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Content</label>

                                  <vue-editor
                                    id="editors0content10"
                                    v-model="row.content10"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="col-12 row"
                                  style="padding: 0; margin-left: 0"
                                >
                                  <div class="form-group col-12">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button10"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-12">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect10"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground11" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background11')"
                            aria-expanded="false"
                            aria-controls="collapseBackground11"
                            data-toggle="collapse"
                            data-target="#collapseBackground11"
                          >
                            <span
                              id="iconToggleBackground11"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground11"
                          class="collapse show"
                          aria-labelledby="TabBackground11"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row">
                              <div class="form-group">
                                <label
                                  >Image 90 * 90
                                  <button
                                    v-if="row.preview11"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview11 = '';
                                      row.image11 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview11"
                                    :src="row.preview11"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage11Change"
                                />
                              </div>
                              <div
                                class="form-group col-12 row"
                                style="padding: 0"
                              >
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>

                                  <vue-editor
                                    id="editors0title11"
                                    v-model="row.title11"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Content</label>

                                  <vue-editor
                                    id="editors0content11"
                                    v-model="row.content11"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>
                                <!-- <div class="col-2" style="padding: 0">
                                                        <label>Font</label>
                                                        <input type="color" 
                                                                v-model="row.contentColor11" 
                                                                style="height: 130px;width: 100%">
                                                    </div> -->
                                <div
                                  class="col-12 row"
                                  style="padding: 0; margin-left: 0"
                                >
                                  <div class="form-group col-12">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button11"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-12">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect11"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground12" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background12')"
                            aria-expanded="false"
                            aria-controls="collapseBackground12"
                            data-toggle="collapse"
                            data-target="#collapseBackground12"
                          >
                            <span
                              id="iconToggleBackground12"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground12"
                          class="collapse show"
                          aria-labelledby="TabBackground12"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row">
                              <div class="form-group">
                                <label
                                  >Image 90 * 90
                                  <button
                                    v-if="row.preview12"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview12 = '';
                                      row.image12 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview12"
                                    :src="row.preview12"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage12Change"
                                />
                              </div>
                              <div
                                class="form-group col-12 row"
                                style="padding: 0"
                              >
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>

                                  <vue-editor
                                    id="editors0title12"
                                    v-model="row.title12"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Content</label>

                                  <vue-editor
                                    id="editors0content12"
                                    v-model="row.content12"
                                    :init="{
                                      height: 300,
                                      menubar: editor.menubar,
                                      plugins: editor.plugins,
                                      toolbar: editor.toolbar,
                                    }"
                                  />
                                </div>

                                <div
                                  class="col-12 row"
                                  style="padding: 0; margin-left: 0"
                                >
                                  <div class="form-group col-12">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button12"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-12">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect12"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 row mt-10">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Home</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
          </form>
          <!-- End Form -->
        </div>
        <!-- End Content Body -->

        <Footer></Footer>
      </div>
      <!-- Content -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";

import iziToast from "izitoast";

export default {
  name: "Home",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        // 1
        preview1: "",
        image1: "",
        title1: "",
        color1: "",
        bgColor1: "",
        lineColor1: "",
        button1: "",
        redirect1: "",

        // 2
        bgColor2: "",
        title2: "",
        color2: "",
        button2: "",
        redirect2: "",

        // 3
        bgColor3: "",
        title3: "",
        color3: "",
        button3: "",
        redirect3: "",

        // 4
        bgColor4: "",
        title4: "",
        color4: "",
        content4: "",
        contentColor4: "",
        button4: "",
        redirect4: "",

        // 5
        preview5: "",
        image5: "",
        bgColor5: "",
        lineColor5: "",
        subTitle5: "",
        subTitleColor5: "",
        title5: "",
        color5: "",
        content5: "",
        contentColor5: "",
        button5: "",
        redirect5: "",

        // 6
        preview6: "",
        image6: "",
        bgColor6: "",
        lineColor6: "",
        subTitle6: "",
        subTitleColor6: "",
        title6: "",
        color6: "",
        content6: "",
        contentColor6: "",
        button6: "",
        redirect6: "",

        // 7
        preview07: "",
        image07: "",
        title07: "",
        color07: "",
        content07: "",
        contentColor07: "",
        button07: "",
        redirect07: "",

        // 8
        preview08: "",
        image08: "",
        title08: "",
        color08: "",
        content08: "",
        contentColor08: "",
        button08: "",
        redirect08: "",

        // 9
        preview09: "",
        image09: "",
        title09: "",
        color09: "",
        content09: "",
        contentColor09: "",
        button09: "",
        redirect09: "",

        // 10
        preview10: "",
        image10: "",
        title10: "",
        color10: "",
        content10: "",
        contentColor10: "",
        button10: "",
        redirect10: "",

        // 11
        preview11: "",
        image11: "",
        title11: "",
        color11: "",
        content11: "",
        contentColor11: "",
        button11: "",
        redirect11: "",

        // 12
        preview12: "",
        image12: "",
        title12: "",
        color12: "",
        content12: "",
        contentColor12: "",
        button12: "",
        redirect12: "",
      },
      editor: {
        api_key: "xahz1dg338xnac8il0tkxph26xcaxqaewi3bd9cw9t4e6j7b",
        menubar: "file edit view insert format tools table tc help",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help",
      },
      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetchRow
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/landingPages/1",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          // 1
          this.row.preview1 = res.data.row.image1;
          this.row.image1 = res.data.row.image1;
          this.row.bgColor1 = res.data.row.bgColor1;
          this.row.lineColor1 = res.data.row.lineColor1;
          this.row.title1 = res.data.row.title1;
          this.row.color1 = res.data.row.color1;
          this.row.button1 = res.data.row.button1;
          this.row.redirect1 = res.data.row.redirect1;

          // 2
          this.row.preview2 = res.data.row.image2;
          this.row.image2 = res.data.row.image2;
          this.row.bgColor2 = res.data.row.bgColor2;
          this.row.title2 = res.data.row.title2;
          this.row.color2 = res.data.row.color2;
          this.row.button2 = res.data.row.button2;
          this.row.redirect2 = res.data.row.redirect2;

          // 3
          this.row.preview3 = res.data.row.image3;
          this.row.image3 = res.data.row.image3;
          this.row.bgColor3 = res.data.row.bgColor3;
          this.row.title3 = res.data.row.title3;
          this.row.color3 = res.data.row.color3;
          this.row.button3 = res.data.row.button3;
          this.row.redirect3 = res.data.row.redirect3;

          // 4
          this.row.bgColor4 = res.data.row.bgColor4;
          this.row.title4 = res.data.row.title4;
          this.row.color4 = res.data.row.color4;
          this.row.content4 = res.data.row.content4;
          this.row.contentColor4 = res.data.row.contentColor4;
          this.row.button4 = res.data.row.button4;
          this.row.redirect4 = res.data.row.redirect4;

          // 5
          this.row.preview5 = res.data.row.image5;
          this.row.image5 = res.data.row.image5;
          this.row.bgColor5 = res.data.row.bgColor5;
          this.row.lineColor5 = res.data.row.lineColor5;
          this.row.subTitle5 = res.data.row.subTitle5;
          this.row.subTitleColor5 = res.data.row.subTitleColor5;
          this.row.title5 = res.data.row.title5;
          this.row.color5 = res.data.row.color5;
          this.row.content5 = res.data.row.content5;
          this.row.contentColor5 = res.data.row.contentColor5;
          this.row.button5 = res.data.row.button5;
          this.row.redirect5 = res.data.row.redirect5;

          // 6
          this.row.preview6 = res.data.row.image6;
          this.row.image6 = res.data.row.image6;
          this.row.bgColor6 = res.data.row.bgColor6;
          this.row.lineColor6 = res.data.row.lineColor6;
          this.row.subTitle6 = res.data.row.subTitle6;
          this.row.subTitleColor6 = res.data.row.subTitleColor6;
          this.row.title6 = res.data.row.title6;
          this.row.color6 = res.data.row.color6;
          this.row.content6 = res.data.row.content6;
          this.row.contentColor6 = res.data.row.contentColor6;
          this.row.button6 = res.data.row.button6;
          this.row.redirect6 = res.data.row.redirect6;

          // 7
          this.row.preview7 = res.data.row.image7;
          this.row.image7 = res.data.row.image7;
          this.row.title7 = res.data.row.title7;
          this.row.color7 = res.data.row.color7;
          this.row.content7 = res.data.row.content7;
          this.row.contentColor7 = res.data.row.contentColor7;
          this.row.button7 = res.data.row.button7;
          this.row.redirect7 = res.data.row.redirect7;

          // 8
          this.row.preview8 = res.data.row.image8;
          this.row.image8 = res.data.row.image8;
          this.row.title8 = res.data.row.title8;
          this.row.color8 = res.data.row.color8;
          this.row.content8 = res.data.row.content8;
          this.row.contentColor8 = res.data.row.contentColor8;
          this.row.button8 = res.data.row.button8;
          this.row.redirect8 = res.data.row.redirect8;

          // 9
          this.row.preview9 = res.data.row.image9;
          this.row.image9 = res.data.row.image9;
          this.row.title9 = res.data.row.title9;
          this.row.color9 = res.data.row.color9;
          this.row.content9 = res.data.row.content9;
          this.row.contentColor9 = res.data.row.contentColor9;
          this.row.button9 = res.data.row.button9;
          this.row.redirect9 = res.data.row.redirect9;

          // 10
          this.row.preview10 = res.data.row.image10;
          this.row.image10 = res.data.row.image10;
          this.row.title10 = res.data.row.title10;
          this.row.color10 = res.data.row.color10;
          this.row.content10 = res.data.row.content10;
          this.row.contentColor10 = res.data.row.contentColor10;
          this.row.button10 = res.data.row.button10;
          this.row.redirect10 = res.data.row.redirect10;

          // 11
          this.row.preview11 = res.data.row.image11;
          this.row.image11 = res.data.row.image11;
          this.row.title11 = res.data.row.title11;
          this.row.color11 = res.data.row.color11;
          this.row.content11 = res.data.row.content11;
          this.row.contentColor11 = res.data.row.contentColor11;
          this.row.button11 = res.data.row.button11;
          this.row.redirect11 = res.data.row.redirect11;

          // 12
          this.row.preview12 = res.data.row.image12;
          this.row.image12 = res.data.row.image12;
          this.row.title12 = res.data.row.title12;
          this.row.color12 = res.data.row.color12;
          this.row.content12 = res.data.row.content12;
          this.row.contentColor12 = res.data.row.contentColor12;
          this.row.button12 = res.data.row.button12;
          this.row.redirect12 = res.data.row.redirect12;
        })
        .catch(() => {})
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/landingPages/1",
        method: "PUT",
        data: {
          // 1
          image1: this.row.image1,
          bgColor1: this.row.bgColor1,
          lineColor1: this.row.lineColor1,
          title1: this.row.title1,
          color1: this.row.color1,
          button1: this.row.button1,
          redirect1: this.row.redirect1,

          // 2
          image2: this.row.image2,
          bgColor2: this.row.bgColor2,
          title2: this.row.title2,
          color2: this.row.color2,
          button2: this.row.button2,
          redirect2: this.row.redirect2,

          // 3
          image3: this.row.image3,
          bgColor3: this.row.bgColor3,
          title3: this.row.title3,
          color3: this.row.color3,
          button3: this.row.button3,
          redirect3: this.row.redirect3,

          // 4
          bgColor4: this.row.bgColor4,
          title4: this.row.title4,
          color4: this.row.color4,
          content4: this.row.content4,
          contentColor4: this.row.contentColor4,
          button4: this.row.button4,
          redirect4: this.row.redirect4,

          // 5
          image5: this.row.image5,
          bgColor5: this.row.bgColor5,
          lineColor5: this.row.lineColor5,
          subTitle5: this.row.subTitle5,
          subTitleColor5: this.row.subTitleColor5,
          title5: this.row.title5,
          color5: this.row.color5,
          content5: this.row.content5,
          contentColor5: this.row.contentColor5,
          button5: this.row.button5,
          redirect5: this.row.redirect5,

          // 6
          image6: this.row.image6,
          bgColor6: this.row.bgColor6,
          lineColor6: this.row.lineColor6,
          subTitle6: this.row.subTitle6,
          subTitleColor6: this.row.subTitleColor6,
          title6: this.row.title6,
          color6: this.row.color6,
          content6: this.row.content6,
          contentColor6: this.row.contentColor6,
          button6: this.row.button6,
          redirect6: this.row.redirect6,

          // 7
          image7: this.row.image7,
          title7: this.row.title7,
          color7: this.row.color7,
          content7: this.row.content7,
          contentColor7: this.row.contentColor7,
          button7: this.row.button7,
          redirect7: this.row.redirect7,

          // 8
          image8: this.row.image8,
          title8: this.row.title8,
          color8: this.row.color8,
          content8: this.row.content8,
          contentColor8: this.row.contentColor8,
          button8: this.row.button8,
          redirect8: this.row.redirect8,

          // 9
          image9: this.row.image9,
          title9: this.row.title9,
          color9: this.row.color9,
          content9: this.row.content9,
          contentColor9: this.row.contentColor9,
          button9: this.row.button9,
          redirect9: this.row.redirect9,

          // 10
          image10: this.row.image10,
          title10: this.row.title10,
          color10: this.row.color10,
          content10: this.row.content10,
          contentColor10: this.row.contentColor10,
          button10: this.row.button10,
          redirect10: this.row.redirect10,

          // 11
          image11: this.row.image11,
          title11: this.row.title11,
          color11: this.row.color11,
          content11: this.row.content11,
          contentColor11: this.row.contentColor11,
          button11: this.row.button11,
          redirect11: this.row.redirect11,

          // 12
          image12: this.row.image12,
          title12: this.row.title12,
          color12: this.row.color12,
          content12: this.row.content12,
          contentColor12: this.row.contentColor12,
          button12: this.row.button12,
          redirect12: this.row.redirect12,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Home Updated Successfully.",
          });
          //this.$router.push({ name: 'home' })
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Upload Featured image
    onImage1Change(e) {
      const file = e.target.files[0];
      this.row.preview1 = URL.createObjectURL(file);
      this.createBase64Image1(file);
    },
    createBase64Image1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1 = e.target.result;
      };
    },

    onImage2Change(e) {
      const file = e.target.files[0];
      this.row.preview2 = URL.createObjectURL(file);
      this.createBase64Image2(file);
    },
    createBase64Image2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2 = e.target.result;
      };
    },

    onImage3Change(e) {
      const file = e.target.files[0];
      this.row.preview3 = URL.createObjectURL(file);
      this.createBase64Image3(file);
    },
    createBase64Image3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3 = e.target.result;
      };
    },

    onImage4Change(e) {
      const file = e.target.files[0];
      this.row.preview4 = URL.createObjectURL(file);
      this.createBase64Image4(file);
    },
    createBase64Image4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image4 = e.target.result;
      };
    },

    onImage5Change(e) {
      const file = e.target.files[0];
      this.row.preview5 = URL.createObjectURL(file);
      this.createBase64Image5(file);
    },
    createBase64Image5(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5 = e.target.result;
      };
    },
    onImage6Change(e) {
      const file = e.target.files[0];
      this.row.preview6 = URL.createObjectURL(file);
      this.createBase64Image6(file);
    },
    createBase64Image6(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image6 = e.target.result;
      };
    },

    onImage7Change(e) {
      const file = e.target.files[0];
      this.row.preview7 = URL.createObjectURL(file);
      this.createBase64Image7(file);
    },
    createBase64Image7(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image7 = e.target.result;
      };
    },

    onImage8Change(e) {
      const file = e.target.files[0];
      this.row.preview8 = URL.createObjectURL(file);
      this.createBase64Image8(file);
    },
    createBase64Image8(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image8 = e.target.result;
      };
    },

    onImage9Change(e) {
      const file = e.target.files[0];
      this.row.preview9 = URL.createObjectURL(file);
      this.createBase64Image9(file);
    },
    createBase64Image9(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image9 = e.target.result;
      };
    },

    onImage10Change(e) {
      const file = e.target.files[0];
      this.row.preview10 = URL.createObjectURL(file);
      this.createBase64Image10(file);
    },
    createBase64Image10(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image10 = e.target.result;
      };
    },

    onImage11Change(e) {
      const file = e.target.files[0];
      this.row.preview11 = URL.createObjectURL(file);
      this.createBase64Image11(file);
    },
    createBase64Image11(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image11 = e.target.result;
      };
    },
    onImage12Change(e) {
      const file = e.target.files[0];
      this.row.preview12 = URL.createObjectURL(file);
      this.createBase64Image12(file);
    },
    createBase64Image12(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image12 = e.target.result;
      };
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "dashboard" });
      }
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },
  },
};
</script>
